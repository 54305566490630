import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useIntl } from "gatsby-plugin-react-intl"
import { useStaticQuery, graphql, Link } from "gatsby"
import "./sitemap.scss"
import Title from "../title"

const SiteMapContent = ({ props }) => {

  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query SiteMapQuery {
      mainMenu_en: allMenuLinkContentMain(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "en" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...mainMenuFields
      }
      mainMenu_fr: allMenuLinkContentMain(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "fr" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...mainMenuFields
      }
      menuPV_en: allMenuLinkContentVerandaPaulVirginieMenu(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "en" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...menuPV
      }
      menuPV_fr: allMenuLinkContentVerandaPaulVirginieMenu(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "fr" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...menuPV
      }
      menuGB_en: allMenuLinkContentVerandaGrandBaieMenu(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "en" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...menuGB
      }
      menuGB_fr: allMenuLinkContentVerandaGrandBaieMenu(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "fr" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...menuGB
      }
      menuPB_en: allMenuLinkContentVerandaPalmarBeachMenu(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "en" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...menuPB
      }
      menuPB_fr: allMenuLinkContentVerandaPalmarBeachMenu(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "fr" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...menuPB
      }
      menuPAB_en: allMenuLinkContentVerandaPointeAuxBichesMenu(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "en" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...menuPAB
      }
      menuPAB_fr: allMenuLinkContentVerandaPointeAuxBichesMenu(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "fr" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...menuPAB
      }
      menuTA_en: allMenuLinkContentVerandaTamarinMenu(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "en" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...menuTA
      }
      menuTA_fr: allMenuLinkContentVerandaTamarinMenu(
        filter: {
          enabled: { eq: true }
          title: { ne: "Dummy" }
          langcode: { eq: "fr" }
        }
        sort: { fields: weight, order: ASC }
      ) {
        ...menuTA
      }
    }
  `)

  let sitePlanResorts, sitePlanPV, sitePlanGB, sitePlanPB, sitePlanPAB, sitePlanTA

  switch (intl.locale) {
    case "fr":
      sitePlanResorts = data.mainMenu_fr.nodes
      sitePlanPV = data.menuPV_fr.nodes
      sitePlanGB = data.menuGB_fr.nodes
      sitePlanPB = data.menuPB_fr.nodes
      sitePlanPAB = data.menuPAB_fr.nodes
      sitePlanTA = data.menuTA_fr.nodes
      break
    default:
      sitePlanResorts = data.mainMenu_en.nodes
      sitePlanPV = data.menuPV_en.nodes
      sitePlanGB = data.menuGB_en.nodes
      sitePlanPB = data.menuPB_en.nodes
      sitePlanPAB = data.menuPAB_en.nodes
      sitePlanTA = data.menuTA_en.nodes
      break
  }

  const createTree = (arrayMenu) => {
    const hashTable = Object.create(null)
    arrayMenu.forEach(
      menu => (hashTable[menu.drupal_id] = { ...menu, childNodes: [] })
    )

    const menuTree = []
    arrayMenu.forEach(menu => {
      if (menu.drupal_parent_menu_item) {
        const stripped_drupal_id = menu.drupal_parent_menu_item.replace(
          "menu_link_content:",
          ""
        )
        hashTable[stripped_drupal_id]?.childNodes?.push(hashTable[menu.drupal_id])
      } else {

        //console.log(menu)
        menuTree.push(hashTable[menu.drupal_id])
      }
    })
    return menuTree
  }

  return (
    <section className="blk-sitemap pt-50">
      <Container>
        <Row>
          <Title heading={2} text={intl.formatMessage({ id: "site-map.title" })} />
        </Row>
        <Row>
          <Title heading={3} size={2} text="Veranda Resorts" />
          <ul className="sitemap-first">
            {createTree(sitePlanResorts).map((elem, key) => (
              <li key={key}>
                <Link to={elem.link.url}>{elem.title}</Link>
                <ul className="sitemap-second">
                  {elem.childNodes?.map((elem, key) => (
                    <li key={key}>
                      <Link to={elem.link.url}>{elem.title}</Link>
                      <ul className="sitemap-third">
                        {elem.childNodes?.map((elem, key) => (
                          <li key={key}>
                            <Link to={elem.link.url}>{elem.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <Title heading={3} size={2} text="Veranda Paul & Virginie" />
          <ul className="sitemap-first">
            {createTree(sitePlanPV).map((elem, key) => (
              <li key={key}>
                <Link to={elem.link.url}>{elem.title}</Link>
                <ul className="sitemap-second">
                  {elem.childNodes?.map((elem, key) => (
                    <li key={key}>
                      <Link to={elem.link.url}>{elem.title}</Link>
                      <ul className="sitemap-third">
                        {elem.childNodes?.map((elem, key) => (
                          <li key={key}>
                            <Link to={elem.link.url}>{elem.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <Title heading={3} size={2} text="Veranda Grand Baie" />
          <ul className="sitemap-first">
            {createTree(sitePlanGB).map((elem, key) => (
              <li key={key}>
                <Link to={elem.link.url}>{elem.title}</Link>
                <ul className="sitemap-second">
                  {elem.childNodes?.map((elem, key) => (
                    <li key={key}>
                      <Link to={elem.link.url}>{elem.title}</Link>
                      <ul className="sitemap-third">
                        {elem.childNodes?.map((elem, key) => (
                          <li key={key}>
                            <Link to={elem.link.url}>{elem.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <Title heading={3} size={2} text="Veranda Palmar Beach" />
          <ul className="sitemap-first">
            {createTree(sitePlanPB).map((elem, key) => (
              <li key={key}>
                <Link to={elem.link.url}>{elem.title}</Link>
                <ul className="sitemap-second">
                  {elem.childNodes?.map((elem, key) => (
                    <li key={key}>
                      <Link to={elem.link.url}>{elem.title}</Link>
                      <ul className="sitemap-third">
                        {elem.childNodes?.map((elem, key) => (
                          <li key={key}>
                            <Link to={elem.link.url}>{elem.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <Title heading={3} size={2} text="Veranda Pointe aux Biches" />
          <ul className="sitemap-first">
            {createTree(sitePlanPAB).map((elem, key) => (
              <li key={key}>
                <Link to={elem.link.url}>{elem.title}</Link>
                <ul className="sitemap-second">
                  {elem.childNodes?.map((elem, key) => (
                    <li key={key}>
                      <Link to={elem.link.url}>{elem.title}</Link>
                      <ul className="sitemap-third">
                        {elem.childNodes?.map((elem, key) => (
                          <li key={key}>
                            <Link to={elem.link.url}>{elem.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <Title heading={3} size={2} text="Veranda Tamarin" />
          <ul className="sitemap-first">
            {createTree(sitePlanTA).map((elem, key) => (
              <li key={key}>
                <Link to={elem.link.url}>{elem.title}</Link>
                <ul className="sitemap-second">
                  {elem.childNodes?.map((elem, key) => (
                    <li key={key}>
                      <Link to={elem.link.url}>{elem.title}</Link>
                      <ul className="sitemap-third">
                        {elem.childNodes?.map((elem, key) => (
                          <li key={key}>
                            <Link to={elem.link.url}>{elem.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </Row>
      </Container>
    </section>
  )
}

export default SiteMapContent
