import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import DefaultBackgroundImage from "../contact/hero-banner.jpg"

const Hero = ({ caption, backgroundImage, description, title }) => {
  const background = backgroundImage ? backgroundImage : DefaultBackgroundImage

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    lazyLoad: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <section
      className="mainBanner height-small">
      <Slider {...settings}>
        <div className="slider-element">
          <div
            className="mainBanner-infos"
            style={{
              backgroundImage:
                "linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%), url(" +
                background +
                ")",
            }}
          >
            <Container>
              <Row className="justify-content-center alignCenter">
                <Col md={10} xs={12}>
                  {caption && <span className="caption">{caption}</span>}
                  <h2 className="mainTitle">{title}</h2>
                  {description && <p className="description">{description}</p>}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Slider>
      <span className="scrollElem"></span>
    </section>
  )
}

export default Hero
