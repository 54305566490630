import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/hero"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import Breadcrumbs from "../components/common/breadcrumbs/breadcrumbs"
import SiteMapContent from "../components/common/sitemap/sitemap"

const Sitemap = ({ pageContext }) => {
  const intl = useIntl()

  const seoData = setSeoData({
    lang: intl.locale,
    title: intl.formatMessage({ id: "site-map.title" }),
    field_seo: {},
  })

  return (
    <Layout pageContext={pageContext} pageClass="pageSitemap">
      <Seo data={seoData} />
      <Hero />
      <Breadcrumbs
        pageTitle={intl.formatMessage({ id: "site-map.title" })}
      />
      <SiteMapContent/>
    </Layout>
  )
}

export default Sitemap
